const data = [
  {
    value: "find a dispensary",
    href: "/locations",
    items: [],
    mobileOnly: true,
  },
  {
    value: "brands",
    href: "/brands",
    items: [],
  },

  {
    value: "about us",
    items: [
      {
        value: "Company Overview",
        content: "We’ve led the cannabis industry for a decade.",
        href: "/about-us/company-overview",
        thumbnail:
          "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt8c29123e3db00ec9/62991a6aae6f0e4156d198d1/About-Us.png",
      },
      {
        value: "News",
        content: "Explore The Cannabist Company updates.",
        href: "/news",
        thumbnail:
          "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/bltb9f40fd7166e550b/62a207b84a98d755621a2603/careers.jpg",
      },
    ],
    thumbnail:
      "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt8c29123e3db00ec9/62991a6aae6f0e4156d198d1/About-Us.png",
  },
  {
    value: "investors",
    href: "https://investors.cannabistcompany.com",
    items: [],
  },
  {
    value: "community impact",
    href: "/community-impact",
    items: [],
  },
  {
    value: "Careers",
    href: "/careers",
    items: [],
  },
];

export default data;
