import React from "react";
import * as footerStyles from "./footer.module.css";
import { Link, navigate } from "gatsby";
import { map as _map } from "lodash";
// import FooterSignUp from "../hubspot-form/footer/Footer";
import Button from "../button/Button";
import FacebookIcon from "@col-care/common/elements/icons/FacebookIcon";
import InstagramIcon from "@col-care/common/elements/icons/InstagramIcon";
import TwitterIcon from "@col-care/common/elements/icons/TwitterIcon";
import LinkedinIcon from "@col-care/common/elements/icons/LinkedinIcon";
import BrandLogo from "@col-care/common/elements/icons/BrandLogo";
import { posthogEvents } from "@col-care/common/utilities/constants";
import { capturePosthogLink } from "@col-care/common/utilities/index";
import footerNav from "./footer.json";

export const openInNewTab = (url) => {
  // 👇️ setting target to _blank with window.open
  window.open(url, "_blank");
};

const Footer = ({ posthog }) => {
  // const hsCooky = props.cookies?.cookies?.hubspotutk;

  const onClickHandler = (item) => {
    // console.log("Handler called");
    capturePosthogLink({
      eventName: posthogEvents.FOOTER_NAVIGATION,
      title: item.title,
      to: item.link,
      posthog,
    });
  };

  return (
    <footer>
      <div className={footerStyles.footerContainer}>
        <div className={footerStyles.row}>
          <a
            href={"/"}
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
              capturePosthogLink({
                eventName: posthogEvents.FOOTER_NAVIGATION,
                title: "The Cannabist Company",
                to: "/",
                posthog,
              });
            }}
            aria-label={"The Cannabist Company"}
          >
            <BrandLogo
              style={{ opacity: "100 !important" }}
              className={footerStyles.logoImage}
              alt="The Cannabist Company"
              color="#A5BDB1"
            />
          </a>
        </div>
        <div className={footerStyles.row}>
          <ul className={footerStyles.importantLinks}>
            {footerNav?.importantLinks?.map((item) => {
              return (
                <li key={item.title}>
                  {item.external ? (
                    <a
                      href={item.link}
                      target={item.external ? "_blank" : "_self"}
                      onClick={() => onClickHandler(item)}
                    >
                      {item.title}
                    </a>
                  ) : (
                    <Link to={item.link} onClick={() => onClickHandler(item)}>
                      {item.title}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={footerStyles.row}>
          <ul>
            <li>About Us</li>
            {footerNav?.about_us_links?.map((item) => {
              return (
                <li key={item.title}>
                  {item.external ? (
                    <a
                      href={item.link}
                      onClick={(e) => {
                        e.preventDefault();
                        onClickHandler(item);
                      }}
                      target={"_blank"}
                    >
                      {item.title}
                    </a>
                  ) : (
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        onClickHandler(item);
                      }}
                      to={item.link}
                    >
                      {item.title}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={footerStyles.row}>
          <ul>
            <li>Other Links</li>
            {footerNav?.other_links?.map((item) => {
              return (
                <li key={item.title}>
                  {item.external ? (
                    <a
                      href={item.link}
                      target={item.external ? "_blank" : "_self"}
                      onClick={() => onClickHandler(item)}
                    >
                      {item.title}
                    </a>
                  ) : (
                    <Link to={item.link} onClick={() => onClickHandler(item)}>
                      {item.title}
                    </Link>
                  )}
                </li>
              );
            })}

            {/* 
            <li>
              <Link to="/need-medical-card">Need a Medical Card?</Link>
            </li>
            <li>
              <Link to="/stash-cash-app">Stash Cash App</Link>
            </li> */}
          </ul>
        </div>
        <div className={`${footerStyles.row} ${footerStyles.last_row}`}>
          <span className={footerStyles.email_signup_header}>
            Get Email & SMS Alerts
          </span>
          {/* <FooterSignUp {...props} /> */}
          <Button
            theme="footerButtonCC"
            label="sign me up!"
            onClick={() => {
              const link = "https://lab.alpineiq.com/join/c/1275/1276";
              openInNewTab(link);
              capturePosthogLink({
                eventName:
                  posthogEvents.FOOTER_NAVIGATION +
                  " " +
                  posthogEvents.EXTERNAL_NAVIGATION,
                title: "sign me up!",
                to: link,
                posthog,
              });
            }}
          />
        </div>
      </div>
      <div className={footerStyles.social_area}>
        <div className={footerStyles.social_area_icons}>
          {footerNav?.social_nav?.map((item) => (
            <a
              key={item.title}
              href={item.link}
              target="_blank"
              rel="noreferrer"
              aria-label={item.title}
              onClick={() =>
                capturePosthogLink({
                  eventName: posthogEvents.SOCIAL_NAVIGATION,
                  title: item.title,
                  to: item.link,
                  posthog,
                })
              }
            >
              {item.iconName === "instagram" && (
                <InstagramIcon
                  color={item.iconColor}
                  className={footerStyles.social_icons}
                />
              )}
              {item.iconName === "facebook" && (
                <FacebookIcon
                  color={item.iconColor}
                  className={footerStyles.social_icons}
                />
              )}
              {item.iconName === "twitter" && (
                <TwitterIcon
                  color={item.iconColor}
                  className={footerStyles.social_icons}
                />
              )}
              {item.iconName === "linkedin" && (
                <LinkedinIcon
                  color={item.iconColor}
                  className={footerStyles.social_icons}
                />
              )}
            </a>
          ))}
        </div>
      </div>
      <div className={footerStyles.copyright}>
        &copy; {new Date().getFullYear()} The Cannabist Company | All Rights
        Reserved
      </div>
    </footer>
  );
};

export default Footer;
