import { useEffect } from "react";
import { useSelector } from "react-redux";
import { datadogRum } from "@datadog/browser-rum";

export const useDataDogRum = (isAppMounted) => {
  const state = useSelector((state) => state);
  useEffect(() => {
    const version = state.hydrate.buildInfoData?.version;
    if (isAppMounted) {
      datadogRum.init({
        applicationId: process.env.GHUB_DATADOG_APP_ID,
        clientToken: process.env.GHUB_DATADOG_CLIENT_TOKEN,
        site: "datadoghq.com",
        service: process.env.GHUB_DATADOG_SERVICE,
        version: version,
        env: process.env.GHUB_ENVIRONMENT,
        sessionSampleRate: 0,
        sessionReplaySampleRate: 0,
        trackInteractions: false,
        defaultPrivacyLevel: "mask-user-input",
      });

      // datadogRum.startSessionReplayRecording();
      // console.info("Starting DataDog RUM monitoring...");

      return () => {};
    }
  }, [isAppMounted]);
};
