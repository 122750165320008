exports.components = {
  "component---src-pages-js": () => import("./../../../src/pages/[...].js" /* webpackChunkName: "component---src-pages-js" */),
  "component---src-templates-batch-js": () => import("./../../../src/templates/batch.js" /* webpackChunkName: "component---src-templates-batch-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-location-medical-menu-js": () => import("./../../../src/templates/location--[...]--medical-menu.js" /* webpackChunkName: "component---src-templates-location-medical-menu-js" */),
  "component---src-templates-location-recreational-menu-js": () => import("./../../../src/templates/location--[...]--recreational-menu.js" /* webpackChunkName: "component---src-templates-location-recreational-menu-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-views-404-js": () => import("./../../../src/views/404.js" /* webpackChunkName: "component---src-views-404-js" */)
}

