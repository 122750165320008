import React, { useState } from "react";
import { navigate } from "gatsby";
import Cookies from "universal-cookie";
import { useSpring, animated } from "react-spring";

import * as bannerStyles from "./banner.module.css";
import Button from "../button/Button";

import CloseIcon from "../../images/close.png";
import XIcon from "../../../../../common/elements/icons/XIcon";

function is_external(url) {
  return url.match(/[a-zA-Z0-9]*:\/\/[^\s]*/g) != null;
}

const Banner = (props) => {
  // console.log("banner props", props);
  const cookies =
    typeof document === "object" ? new Cookies(document.cookie) : {};

  const springProps = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  const data = props.bannerData;
  if (!data) {
    return null;
  }

  const newsReference = data.news_banner_config.news_reference
    ? data.news_banner_config.news_reference[0]
    : null;

  const newsReferenceTitle = newsReference?.title;
  const newsReferenceLink = newsReference?.source_link.href;

  let bannerLink = null;
  if (newsReference && data.news_banner_config.link_to_referenced_content) {
    bannerLink = newsReferenceLink;
  }
  // fall-through intentionally
  if (data.news_banner_config.custom_link_path) {
    bannerLink = data.news_banner_config.custom_link_path;
  }

  let bannerTitle = null;
  if (data.title) {
    bannerTitle = data.title;
  } else if (newsReference) {
    bannerTitle = newsReferenceTitle;
  }

  return (
    <>
      {props.actuallyShowBanner && (
        <div
          style={{
            springProps,
            background: `#${props.bannerData.news_banner_config.background_color}`,
          }}
          id="header-banner"
          className={bannerStyles.banner}
        >
          <animated.div className={bannerStyles.bannerSection}>
            <div className={bannerStyles.bannerContainer}>
              {props.bannerData.news_banner_config.base_64_encoded_image && (
                <img
                  className={bannerStyles.bannerImage}
                  src={`data:image/png;base64,${props.bannerData.news_banner_config.base_64_encoded_image}`}
                  title="The Cannabist Company"
                />
              )}
            </div>

            <div>
              <span className={bannerStyles.bannerInfoDescription}>
                {bannerTitle}
              </span>
            </div>
            <div>
              <span className={bannerStyles.btnContainer}>
                <Button
                  label={data.news_banner_config.button_label || `Let's go`}
                  theme="smallBlue"
                  style={{
                    background: `#${props.bannerData.news_banner_config.button_background_color}`,
                    borderColor: `#${props.bannerData.news_banner_config.button_outline_color}`,
                    color: `#${props.bannerData.news_banner_config.button_outline_color}`,
                  }}
                  onClick={() => {
                    cookies.set(props.cookyKey, Date.now(), { path: "/" });
                    props.setShowBanner(false);
                    if (bannerLink) {
                      if (is_external(bannerLink)) {
                        window.open(bannerLink);
                      } else {
                        navigate(bannerLink);
                      }
                    }
                  }}
                >
                  {data.news_banner_config.button_label || `Let's go`}
                </Button>
              </span>
            </div>

            {props.bannerInfoBig && <p>{props.bannerInfoBig}</p>}
            {props.bannerHeader && <h3>{props.bannerHeader}</h3>}
            <div
              className={bannerStyles.closeMe}
              onClick={() => {
                props.setShowBanner(!props.showBanner);
                cookies.set(props.cookyKey, Date.now(), { path: "/" });
              }}
            >
              <XIcon
                className={bannerStyles.bannerExit}
                alt="close"
                color={props.bannerData.news_banner_config.button_outline_color}
              />
            </div>
          </animated.div>
        </div>
      )}
    </>
  );
};

export default Banner;
