import React from "react";
import Navbar from "../navbar/Navbar/Navbar";

const Header = (props) => {
  // console.log({ headerProps: props });
  return [
    props &&
      props.storybookArgs &&
      React.createElement(
        "style",
        { key: "style", type: "text/css" },
        props.storybookArgs.styles
      ),
    <Navbar
      posthog={props.posthog}
      bannerData={props.bannerData}
      key="navbar"
    />,
  ];
};

Header.displayName = "Header";

export default Header;
