import React from "react"; // eslint-disable-line

const CannabistCompanyLogo = ({ className }) => (
  <svg
    className={className}
    width="200"
    viewBox="0 0 87 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4693_4776)">
      <path
        d="M43.3943 0.318359C42.7143 2.28491 41.9424 4.0493 41.1842 5.73557C40.6604 6.82453 40.1964 7.85835 39.7966 8.84622C38.5055 11.8696 37.5176 14.8424 37.5728 18.4814V18.509C37.5728 18.509 37.5728 18.5366 37.5728 18.5503C37.5176 22.1894 38.5055 25.1576 39.7966 28.1809C40.1964 29.1688 40.6558 30.2072 41.1842 31.2962C41.9424 32.9779 42.7189 34.7468 43.3943 36.7088C44.0743 34.7422 44.8462 32.9779 45.6044 31.2916C46.1282 30.2072 46.5923 29.1688 46.992 28.1809C48.2831 25.1576 49.271 22.1848 49.2159 18.5457C49.2159 18.5366 49.2159 18.5274 49.2159 18.5182C49.2159 18.5044 49.2159 18.4906 49.2159 18.4768C49.271 14.8378 48.2831 11.8696 46.992 8.84622C46.5923 7.85835 46.1328 6.81994 45.6044 5.73098C44.8462 4.0493 44.0697 2.28032 43.3943 0.318359Z"
        fill="#A5BDB1"
      />
      <path
        d="M23.0671 66.2308L28.397 62.5274C30.0649 61.3696 30.6209 59.5776 31.2687 57.5008C32.298 54.1926 33.5799 50.0711 40.1688 48.3159L41.7218 47.9023L41.7494 49.5059C41.878 56.9218 37.8346 62.4999 30.9333 64.4251L30.8736 64.4389L23.0625 66.2262L23.0671 66.2308Z"
        fill="#A5BDB1"
      />
      <path
        d="M38.9512 38.2386L37.3752 37.9354C30.0879 36.5386 25.461 31.4384 24.9923 24.289L24.9648 22.7084L24.8545 16.2344L27.377 22.1984C28.1673 24.0684 29.8076 24.9828 31.7053 26.0396C34.7332 27.7259 38.5009 29.8302 38.8639 36.6351L38.9466 38.2386H38.9512Z"
        fill="#A5BDB1"
      />
      <path
        d="M21.822 51.422C16.9837 51.422 12.1408 49.8597 7.65637 46.7077L7.60583 46.671L0.415039 41.0746L6.64552 42.8481C10.3351 43.9003 13.4825 42.6276 17.1308 41.1481C22.7685 38.8691 29.1598 36.2822 38.3355 42.8941L39.6358 43.8314L38.4136 44.8698C33.2859 49.2211 27.5562 51.4174 21.822 51.4174V51.422Z"
        fill="#A5BDB1"
      />
      <path
        d="M63.7629 66.2301L55.8967 64.429C48.9953 62.5038 44.952 56.9257 45.0806 49.5098L45.1082 47.9062L46.6612 48.3198C53.2501 50.075 54.532 54.1965 55.5612 57.5047C56.2045 59.5815 56.7651 61.3735 58.433 62.5313L63.7629 66.2347V66.2301Z"
        fill="#A5BDB1"
      />
      <path
        d="M47.8789 38.2386L47.9616 36.6351C48.3246 29.8303 52.0923 27.7259 55.1202 26.0396C57.0178 24.9828 58.6582 24.0684 59.4485 22.1984L61.971 16.2344L61.8331 24.2936C61.3691 31.443 56.7375 36.5432 49.4503 37.94L47.8743 38.2432L47.8789 38.2386Z"
        fill="#A5BDB1"
      />
      <path
        d="M65.0082 51.422C59.2785 51.422 53.5489 49.2303 48.4165 44.8744L47.1943 43.836L48.4947 42.8941C57.6658 36.2822 64.0571 38.8691 69.6994 41.1481C73.3477 42.6276 76.4997 43.8957 80.1847 42.8481L86.4151 41.0746L79.1738 46.7031C74.6893 49.8551 69.8511 51.4174 65.0082 51.4174V51.422Z"
        fill="#A5BDB1"
      />
    </g>
    <defs>
      <clipPath id="clip0_4693_4776">
        <rect
          width="86"
          height="65.9117"
          fill="white"
          transform="translate(0.415039 0.318359)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CannabistCompanyLogo;
