import React from "react"; // eslint-disable-line

const BrandLogo = ({ className, color }) => (
  <svg
    width="68"
    height="55"
    viewBox="0 0 68 55"
    fill={color}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4693_4948)">
      <g clipPath="url(#clip1_4693_4948)">
        <path
          d="M32.7908 2.5C32.2785 3.98165 31.6969 5.31098 31.1257 6.58146C30.7311 7.40191 30.3814 8.18081 30.0802 8.9251C29.1075 11.203 28.3632 13.4428 28.4047 16.1845V16.2053C28.4047 16.2053 28.4047 16.226 28.4047 16.2364C28.3632 18.9782 29.1075 21.2145 30.0802 23.4924C30.3814 24.2366 30.7276 25.019 31.1257 25.8395C31.6969 27.1065 32.2819 28.4393 32.7908 29.9175C33.3032 28.4358 33.8848 27.1065 34.456 25.836C34.8506 25.019 35.2002 24.2366 35.5014 23.4924C36.4742 21.2145 37.2185 18.9747 37.1769 16.233C37.1769 16.226 37.1769 16.2191 37.1769 16.2122C37.1769 16.2018 37.1769 16.1914 37.1769 16.181C37.2185 13.4393 36.4742 11.203 35.5014 8.9251C35.2002 8.18081 34.8541 7.39845 34.456 6.578C33.8848 5.31098 33.2997 3.97819 32.7908 2.5Z"
          fill={color}
        />
        <path
          d="M17.4756 52.1607L21.4913 49.3705C22.748 48.4981 23.1668 47.148 23.6549 45.5833C24.4304 43.0908 25.3962 39.9855 30.3604 38.6631L31.5305 38.3516L31.5513 39.5597C31.6482 45.1471 28.6019 49.3497 23.4022 50.8002L23.3572 50.8106L17.4722 52.1572L17.4756 52.1607Z"
          fill={color}
        />
        <path
          d="M29.4431 31.0698L28.2557 30.8413C22.7653 29.7889 19.2792 25.9463 18.9261 20.5597L18.9053 19.3689L18.8223 14.4912L20.7228 18.9846C21.3182 20.3936 22.5541 21.0825 23.9838 21.8787C26.2651 23.1492 29.1038 24.7347 29.3773 29.8616L29.4396 31.0698H29.4431Z"
          fill={color}
        />
        <path
          d="M16.5375 41.0027C12.8922 41.0027 9.24345 39.8257 5.86473 37.4509L5.82665 37.4232L0.408936 33.2067L5.10314 34.543C7.88296 35.3357 10.2543 34.3768 13.003 33.2621C17.2506 31.545 22.066 29.5961 28.9792 34.5776L29.9589 35.2838L29.038 36.0662C25.1747 39.3445 20.8578 40.9992 16.5375 40.9992V41.0027Z"
          fill={color}
        />
        <path
          d="M48.1368 52.1602L42.2102 50.8031C37.0105 49.3526 33.9642 45.15 34.0611 39.5627L34.0819 38.3545L35.2519 38.6661C40.2162 39.9885 41.182 43.0937 41.9574 45.5862C42.4421 47.1509 42.8644 48.501 44.1211 49.3734L48.1368 52.1636V52.1602Z"
          fill={color}
        />
        <path
          d="M36.1692 31.0698L36.2315 29.8616C36.505 24.7347 39.3437 23.1492 41.625 21.8787C43.0548 21.0825 44.2906 20.3936 44.886 18.9846L46.7866 14.4912L46.6827 20.5632C46.3331 25.9498 42.8436 29.7924 37.3532 30.8448L36.1658 31.0732L36.1692 31.0698Z"
          fill={color}
        />
        <path
          d="M49.075 41.0027C44.7581 41.0027 40.4412 39.3514 36.5744 36.0696L35.6536 35.2872L36.6333 34.5776C43.543 29.5961 48.3584 31.545 52.6095 33.2621C55.3581 34.3768 57.7329 35.3323 60.5093 34.543L65.2035 33.2067L59.7477 37.4474C56.369 39.8222 52.7237 40.9992 49.075 40.9992V41.0027Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4693_4948">
        <rect
          width="67.8082"
          height="55"
          fill="white"
          transform="translate(0.0959473)"
        />
      </clipPath>
      <clipPath id="clip1_4693_4948">
        <rect
          width="64.7945"
          height="49.6595"
          fill="white"
          transform="translate(0.408691 2.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default BrandLogo;
