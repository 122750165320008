// extracted by mini-css-extract-plugin
export var almostClose = "Sidebar-module--almostClose--42ee0";
export var almostOpen = "Sidebar-module--almostOpen--c4bb6";
export var backButton = "Sidebar-module--backButton--7e749";
export var close = "Sidebar-module--close--f820f";
export var cross = "Sidebar-module--cross--a3cf4";
export var crossedLine = "Sidebar-module--crossedLine--e83ea";
export var header = "Sidebar-module--header--b097f";
export var line = "Sidebar-module--line--e3bdb";
export var locationIcon = "Sidebar-module--locationIcon--e552c";
export var logoIcon = "Sidebar-module--logoIcon--985f4";
export var mobileSidebar = "Sidebar-module--mobile-sidebar--e0b42";
export var navItem = "Sidebar-module--navItem--e09d2";
export var navItemWrapper = "Sidebar-module--navItemWrapper--2fffc";
export var navItems = "Sidebar-module--navItems--13eec";
export var openSidebar = "Sidebar-module--openSidebar--d8b51";
export var sidebar = "Sidebar-module--sidebar--47eb5";
export var subNavItemWrapper = "Sidebar-module--subNavItemWrapper--f7378";
export var subitemsArrow = "Sidebar-module--subitemsArrow--b6e20";
export var subnavItems = "Sidebar-module--subnavItems--19374";