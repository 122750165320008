import React from "react"; // eslint-disable-line

const CannabistCompanyLogoHorizontal = ({ className, color1, color2 }) => (
  <svg
    height="40"
    viewBox="0 0 201 40"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4847_17847)">
      <path
        d="M18.1291 4.76758C17.8442 5.59097 17.5175 6.33478 17.1978 7.04054C16.9754 7.49721 16.7808 7.93312 16.614 8.34828C16.0685 9.61796 15.6515 10.8669 15.6758 12.3926V12.4029C15.6758 12.4029 15.6758 12.4133 15.6758 12.4202C15.6515 13.9494 16.0685 15.1949 16.614 16.4645C16.7808 16.8797 16.9754 17.3156 17.1978 17.7723C17.5175 18.478 17.8442 19.2218 18.1291 20.0452C18.4141 19.2218 18.7407 18.478 19.0604 17.7723C19.2828 17.3156 19.4774 16.8797 19.6442 16.4645C20.1898 15.1949 20.6067 13.9459 20.5824 12.4202C20.5824 12.4168 20.5824 12.4133 20.5824 12.4099C20.5824 12.4029 20.5824 12.3995 20.5824 12.3926C20.6067 10.8634 20.1898 9.61796 19.6442 8.34828C19.4774 7.93312 19.2828 7.49721 19.0604 7.04054C18.7407 6.33478 18.4141 5.59097 18.1291 4.76758Z"
        fill={color1}
      />
      <path
        d="M9.55285 32.4442L11.8011 30.8908C12.5031 30.403 12.7394 29.6523 13.0104 28.7805C13.4448 27.3897 13.9869 25.6599 16.7633 24.923L17.4166 24.75L17.427 25.4246C17.4826 28.5383 15.7765 30.8804 12.8645 31.69L12.8401 31.6969L9.5459 32.4476L9.55285 32.4442Z"
        fill={color1}
      />
      <path
        d="M16.2527 20.6918L15.589 20.5638C12.5171 19.9756 10.5642 17.8341 10.3661 14.8347L10.3557 14.1704L10.3105 11.4512L11.3739 13.9559C11.7075 14.7413 12.399 15.1253 13.1982 15.5681C14.477 16.2773 16.065 17.1595 16.2179 20.0172L16.2527 20.6918Z"
        fill={color1}
      />
      <path
        d="M9.0279 26.2272C6.98811 26.2272 4.94484 25.5699 3.05447 24.2483L3.03362 24.2345L0 21.882L2.62706 22.6258C4.18383 23.0651 5.51126 22.5324 7.05066 21.9131C9.42752 20.9548 12.1241 19.8719 15.9917 22.6465L16.5407 23.0409L16.023 23.4768C13.8581 25.3035 11.443 26.2238 9.02442 26.2238L9.0279 26.2272Z"
        fill={color1}
      />
      <path
        d="M26.7192 32.4442L23.4007 31.69C20.4887 30.8804 18.786 28.5417 18.8381 25.4246L18.8485 24.75L19.5018 24.923C22.2817 25.6599 22.8204 27.3897 23.2547 28.7804C23.5258 29.6523 23.7621 30.403 24.464 30.8908L26.7123 32.4442H26.7192Z"
        fill={color1}
      />
      <path
        d="M20.0191 20.6918L20.0538 20.0172C20.2067 17.1595 21.7948 16.2773 23.0736 15.5681C23.8728 15.1218 24.5643 14.7413 24.8979 13.9559L25.9612 11.4512L25.9022 14.8347C25.7041 17.8376 23.7547 19.9791 20.6793 20.5638L20.0156 20.6918H20.0191Z"
        fill={color1}
      />
      <path
        d="M27.2438 26.2274C24.8288 26.2274 22.4102 25.3072 20.2453 23.4805L19.7275 23.0446L20.2766 22.6502C24.1442 19.8756 26.8407 20.9619 29.2176 21.9167C30.757 22.536 32.0879 23.0723 33.6412 22.6294L36.2683 21.8856L33.2138 24.2485C31.3234 25.5701 29.2802 26.2274 27.2404 26.2274H27.2438Z"
        fill={color1}
      />
      <path
        d="M43.5273 19.9864V19.9379C43.5273 15.2571 47.0718 11.4238 52.1522 11.4238C55.2726 11.4238 57.1387 12.4583 58.6746 13.9632L56.3603 16.6202C55.085 15.4681 53.7854 14.7623 52.1313 14.7623C49.3444 14.7623 47.3359 17.0664 47.3359 19.8895V19.9379C47.3359 22.761 49.2957 25.1135 52.1313 25.1135C54.0217 25.1135 55.1788 24.3593 56.4785 23.183L58.7928 25.5113C57.09 27.3242 55.2031 28.452 52.0131 28.452C47.1448 28.452 43.5308 24.7122 43.5308 19.9829L43.5273 19.9864Z"
        fill={color2}
      />
      <path
        d="M68.7866 11.5898H72.1433L79.2322 28.1752H75.4271L73.9156 24.4838H66.9205L65.4089 28.1752H61.7012L68.79 11.5898H68.7866ZM72.6125 21.2802L70.4163 15.942L68.2201 21.2802H72.6159H72.6125Z"
        fill={color2}
      />
      <path
        d="M83.2002 11.707H86.557L94.3061 21.8437V11.707H97.8957V28.1713H94.7996L86.7898 17.7025V28.1713H83.2002V11.707Z"
        fill={color2}
      />
      <path
        d="M103.286 11.707H106.639L114.388 21.8437V11.707H117.978V28.1713H114.882L106.872 17.7025V28.1713H103.282V11.707H103.286Z"
        fill={color2}
      />
      <path
        d="M129.042 11.5898H132.395L139.484 28.1752H135.679L134.168 24.4838H127.172L125.661 28.1752H121.953L129.042 11.5898ZM132.871 21.2802L130.675 15.942L128.479 21.2802H132.875H132.871Z"
        fill={color2}
      />
      <path
        d="M143.455 11.707H151.135C153.025 11.707 154.512 12.226 155.458 13.1635C156.215 13.9177 156.59 14.8345 156.59 15.9624V16.0108C156.59 17.8686 155.597 18.903 154.415 19.5638C156.33 20.2938 157.511 21.3974 157.511 23.6116V23.66C157.511 26.6699 155.054 28.1748 151.322 28.1748H143.455V11.7105V11.707ZM150.353 18.3633C151.958 18.3633 152.976 17.8444 152.976 16.6231V16.5747C152.976 15.4918 152.125 14.8795 150.589 14.8795H147V18.3599H150.353V18.3633ZM151.322 24.9954C152.928 24.9954 153.897 24.4315 153.897 23.2068V23.1584C153.897 22.0513 153.07 21.3697 151.204 21.3697H147V24.9919H151.322V24.9954Z"
        fill={color2}
      />
      <path d="M162.099 11.707H165.737V28.1713H162.099V11.707Z" fill={color2} />
      <path
        d="M170.157 25.7743L172.308 23.2107C173.795 24.4354 175.356 25.2103 177.246 25.2103C178.733 25.2103 179.633 24.6222 179.633 23.657V23.6085C179.633 22.6917 179.067 22.2212 176.301 21.5155C172.968 20.6679 170.821 19.7511 170.821 16.4817V16.4333C170.821 13.4476 173.233 11.4688 176.61 11.4688C179.022 11.4688 181.075 12.2229 182.754 13.5618L180.863 16.2915C179.397 15.2812 177.958 14.6689 176.565 14.6689C175.172 14.6689 174.438 15.3055 174.438 16.1046V16.1531C174.438 17.2359 175.147 17.5888 178.007 18.3153C181.364 19.1871 183.251 20.3842 183.251 23.2557V23.3041C183.251 26.5734 180.745 28.407 177.18 28.407C174.675 28.407 172.148 27.5352 170.164 25.7708L170.157 25.7743Z"
        fill={color2}
      />
      <path
        d="M191.331 15.0456H186.299V11.707H200.004V15.0456H194.972V28.1713H191.334V15.0456H191.331Z"
        fill={color2}
      />
      <path
        d="M110.055 1.17627H108.596V0H112.863V1.17627H111.403V4.84344H110.055V1.17627Z"
        fill={color2}
      />
      <path
        d="M119.704 0H121.052V1.81283H122.783V0H124.131V4.84344H122.783V3.00294H121.052V4.84344H119.704V0Z"
        fill={color2}
      />
      <path
        d="M131.248 0H135.161V1.14167H132.582V1.87511H134.918V2.93374H132.582V3.70178H135.196V4.84344H131.248V0Z"
        fill={color2}
      />
      <path
        d="M84.625 37.4914V37.4776C84.625 36.0661 85.7161 34.959 87.1895 34.959C88.1833 34.959 88.8227 35.3741 89.2536 35.9692L88.2389 36.7511C87.9609 36.4051 87.6412 36.1837 87.1756 36.1837C86.4945 36.1837 86.015 36.758 86.015 37.4637V37.4776C86.015 38.2041 86.4945 38.7715 87.1756 38.7715C87.6829 38.7715 87.9818 38.5362 88.2737 38.1833L89.2884 38.9029C88.8297 39.5326 88.2111 39.9962 87.1339 39.9962C85.7439 39.9962 84.625 38.9375 84.625 37.4914Z"
        fill={color2}
      />
      <path
        d="M95.8145 37.4914V37.4776C95.8145 36.0868 96.9403 34.959 98.4415 34.959C99.9427 34.959 101.055 36.073 101.055 37.4637V37.4776C101.055 38.8683 99.9288 39.9962 98.4276 39.9962C96.9264 39.9962 95.8145 38.8822 95.8145 37.4914ZM99.6786 37.4914V37.4776C99.6786 36.7787 99.1712 36.1698 98.4276 36.1698C97.684 36.1698 97.1975 36.7649 97.1975 37.4637V37.4776C97.1975 38.1764 97.7048 38.7853 98.4415 38.7853C99.1782 38.7853 99.6786 38.1903 99.6786 37.4914Z"
        fill={color2}
      />
      <path
        d="M108.012 35.0566H109.436L110.597 36.9317L111.758 35.0566H113.182V39.9001H111.841V37.1186L110.597 39.0144H110.569L109.332 37.1324V39.9001H108.012V35.0566Z"
        fill={color2}
      />
      <path
        d="M120.299 35.0566H122.384C123.614 35.0566 124.406 35.6863 124.406 36.7588V36.7726C124.406 37.9143 123.524 38.5162 122.314 38.5162H121.647V39.9001H120.299V35.0566ZM122.286 37.4645C122.766 37.4645 123.058 37.2154 123.058 36.8349V36.821C123.058 36.4059 122.766 36.1845 122.28 36.1845H121.647V37.4645H122.286Z"
        fill={color2}
      />
      <path
        d="M132.427 35.0215H133.726L135.797 39.8995H134.352L133.997 39.0346H132.121L131.773 39.8995H130.355L132.427 35.0215ZM133.608 37.9898L133.066 36.6129L132.517 37.9898H133.608Z"
        fill={color2}
      />
      <path
        d="M142.524 35.0566H143.782L145.784 37.6167V35.0566H147.118V39.9001H145.937L143.859 37.2431V39.9001H142.524V35.0566Z"
        fill={color2}
      />
      <path
        d="M155.639 38.0873L153.79 35.0566H155.326L156.327 36.821L157.334 35.0566H158.843L156.994 38.0665V39.9001H155.639V38.0873Z"
        fill={color2}
      />
    </g>
    <defs>
      <clipPath id="clip0_4847_17847">
        <rect width="200" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CannabistCompanyLogoHorizontal;
